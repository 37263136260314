import React, { useState, useEffect } from 'react';
import './App.css';
// import qfBanner from './qfbanner.png';

function App() {
  const [inputValue, setInputValue] = useState('');
  const [masterList, setMasterList] = useState([]);
  const [mintedFren, setMintedFren] = useState(null);
  const [hoveredItem, setHoveredItem] = useState(null);

  useEffect(() => {
    fetch('https://frenzone.net/masterlist/masterlist.json')
      .then((response) => response.json())
      .then(setMasterList)
      .catch((error) => console.error('Error fetching master list:', error));
  }, []);

  const decodeBlobToImageSrc = (blob) => {
    blob = blob.replace('STAMP:', '');
    return `data:image/png;base64,${blob}`;
  };

  const handleSubmit = (event) => {
    event.preventDefault();
  
    // Check if the length of inputValue is within the required range
    if (inputValue.length < 30 || inputValue.length > 46) {
      alert("Error: Must be a Bitcoin or XCP Legacy address.");
      return; // Exit the function early
    }
  
    const mintUrl = `https://fren-to-stamp-e5cf03af697f.herokuapp.com/mint?address=${inputValue}`;
    fetch(mintUrl)
      .then((response) => response.json())
      .then((data) => {
        console.log(data)
        setMintedFren(data);
      })
      .catch((error) => {
        console.error('Error minting NFT:', error);
        setMintedFren(null);
      });
  };
  
  const copyToClipboard = (text) => {
    navigator.clipboard.writeText(text).then(() => {
      alert('Blob data copied to clipboard');
    }).catch(err => {
      console.error('Could not copy text: ', err);
    });
  };

  return (
    <div className="app">
      <header className="app-header">
        <h1>QUESTFRENS</h1>
        <img src={'qfbanner.png'} alt="QF Banner" style={{ maxWidth: '500px', width: '90vw' }}  />
      </header>

      <section className="subheader-section" style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
        <h2>About QuestFrens</h2>
        <p  style={{ maxWidth: "500px", textAlign: "left"}}> QuestFrens are a set of up to 4,096 algorithmically generated NFTs that utilise the STAMP protocol to permanently engrave the assets on to the Bitcoin chain, ensuring they exist as long as Bitcoin does.
          <br></br>
        There are a number of traits of varying rarity that are determined by the unique hash generated by your mint token.</p>
      </section>

      <section className="minting-section" style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
        <h4>Instructions</h4>
        <br></br>
        <ol style={{ maxWidth: "500px", textAlign: "left"}}>
          <li>Enter your Bitcoin address below to generate your unique Fren data.</li>
          <li>Your address is used to generate a unique image in the STAMP base64 format.</li>
          <li>Go to ninja wallet, or your preferred wallet and begin to issue a new asset.</li>
          <li>Input the provided blob string as the description of a new STAMP asset, issue it, and lock it.</li>
          <li>Once issued and locked, your NFT will be automatically added to the QuestFrens STAMP collection.</li>
          <li>Each address can only mint one NFT.</li>
          <br></br>
          <p style={{color: "orange"}}>WARNING: Simply generating your Fren's data will not make an NFT appear in your wallet, you must create the STAMP Asset yourself and use the provided blob data as the description.</p>
        </ol>

        <br></br>
        <br></br>
        
        <form onSubmit={handleSubmit} className="minting-form">
          <input
            type="text"
            className="text-input"
            value={inputValue}
            onChange={(e) => setInputValue(e.target.value)}
            placeholder="Enter your Bitcoin address"
          />
          <button type="submit" className="submit-button">Generate Fren</button>
        </form>
      </section>

      {mintedFren && (
        <section className="splash-grid">
          <h2>Fren Minted</h2>
          <div className="fren-item">
            <img
              src={decodeBlobToImageSrc(mintedFren.stamp)}
              alt={`Minted ${mintedFren.address}`}
              className="pixel-art-image"
            />
            <p>Owner: {mintedFren.address}</p>
            <div className="blob-container">
              <p className="blob-text">{mintedFren.stamp}</p>
              <button onClick={() => copyToClipboard(mintedFren.stamp)} className="copy-button">Copy Blob</button>
            </div>
          </div>
          <div className="instructions">
            <p>1. Copy the blob data and paste it into a new STAMP asset's description.</p>
            <p>2. When the asset is issued and locked, it will be automatically added to the QuestFrens STAMP collection.</p>
            <p>3. The new QuestFren will appear on collections.</p>

          </div>
        </section>
      )}

      <section className="collection-section">
        <h2>Current Collection</h2>
        <p>Number of Frens: {masterList.length}</p>
        <div className="masterlist-grid">
          {masterList.map((item) => (
            <div 
              key={item.asset} 
              className="masterlist-item"
              onMouseEnter={() => setHoveredItem(item.asset)}
              onMouseLeave={() => setHoveredItem(null)}
            >
              <img
                src={decodeBlobToImageSrc(item.blob)}
                alt={item.asset}
                className="pixel-art-image"
              />
              <p>{item.asset}</p>
              {hoveredItem === item.asset && (
                <div className="buttons-overlay">
                  <a href={`https://stamped.ninja/stamp/${item.asset}`} target="_blank" rel="noopener noreferrer" className="overlay-button">Ninja</a>
                  <a href={`https://xchain.io/asset/${item.asset}`} target="_blank" rel="noopener noreferrer" className="overlay-button">XCP</a>
                </div>
              )}
            </div>
          ))}
        </div>
      </section>

      <a href="https://stamped.ninja/collection/questfrens" className="ninja-collection-link" target="_blank" rel="noopener noreferrer">
        Visit collection on Ninja
      </a>
    </div>
  )
}

export default App;
